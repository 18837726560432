import Layout from "components/layout";
import SEO from "components/seo";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React from "react";

interface BlogPostInterface {
  uri: string;
  pageContext: {
    id: string;
  };
  data: {
    mdx: {
      frontmatter: {
        title: string;
        description: string;
        date: string;
        tags: string[];
        updated: string;
        draft: boolean;
      };
      body: string;
      fields: {
        slug: string;
      };
    };
  };
}

export const query = graphql`
  query BlogPosts($id: String) {
    mdx(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        description
        images
        title
        updated
        tags
        draft
      }
      body
    }
  }
`;

const BlogPosts: React.FC<BlogPostInterface> = ({ data }) => {
  const {
    body,
    frontmatter: { title, description, draft, date },
  } = data.mdx;

  return (
    <>
      <SEO title={title} description={description} article />
      <Layout>
        <article className="container prose dark:prose-dark mx-auto p-6">
          {draft && (
            <div className="bg-red-600 text-white p-3 text-2xl mb-10 font-black rounded text-center">
              Draft
            </div>
          )}
          <p className="text-gray-500 dark:text-gray-300 text-sm">{date}</p>
          <h1 className="font-serif">{title}</h1>
          <MDXRenderer>{body}</MDXRenderer>
        </article>
      </Layout>
    </>
  );
};

export default BlogPosts;
